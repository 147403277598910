<template>
    <form @submit.prevent="handleAdd"
          class="flex-1 overflow-y-auto h-full">

        <PageHead sticky
                  title="Neue Produktkategorie">
            <template #actions>
                <c-button :disabled="noChange">
                    Speichern
                    <component :is="saveIcon" class="h-5 w-5 rounded-full text-white ml-2"/>
                </c-button>
            </template>
        </PageHead>

        <nav class="relative z-0 flex divide-x divide-gray-200" aria-label="Tabs">
            <a v-for="(tab, tabIdx) in tabs"
               @click.prevent="currentTab = tabIdx"
               :key="tab.name"
               href="#"
               :class="[
                   currentTab === tabIdx ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                   'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10',
               ]">
                <span>{{ tab.name }}</span>
                <span aria-hidden="true"
                      :class="[currentTab === tabIdx ? 'bg-gray-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']"/>
            </a>
        </nav>
        <div class="p-6 bg-white">
            <div v-if="currentTab === 0">
                <div class="grid grid-cols-4 gap-6">
                    <div class="col-span-12">
                        <label for="name_de" class="block text-sm font-medium text-gray-700">Name</label>
                        <input type="text"
                               v-model="form.name_de"
                               name="name_de"
                               id="name_de"
                               autocomplete="cc-given-name"
                               class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"/>
                    </div>
                    <div class="col-span-12">
                        <label for="description_de" class="block text-sm font-medium text-gray-700">
                            Beschreibung
                        </label>
                        <div class="mt-1">
                            <textarea id="description_de"
                                      v-model="form.desc_de"
                                      name="description_de"
                                      rows="4"
                                      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"/>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="currentTab === 1">
                <div class="grid grid-cols-4 gap-6">
                    <div class="col-span-12">
                        <label for="name_en" class="block text-sm font-medium text-gray-700">Name</label>
                        <input type="text"
                               v-model="form.name_en"
                               name="name_en"
                               id="name_en"
                               autocomplete="cc-given-name"
                               class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"/>
                    </div>
                    <div class="col-span-12">
                        <label for="description_en" class="block text-sm font-medium text-gray-700">
                            Description
                        </label>
                        <div class="mt-1">
                            <textarea id="description_en"
                                      v-model="form.desc_en"
                                      name="description_en"
                                      rows="4"
                                      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import {mapActions} from 'vuex';
import {SaveIcon} from '@heroicons/vue/outline';
import {diff} from 'deep-object-diff';

export default {
    name: 'ProductCategoryAdd',
    data() {
        return {
            currentTab: 0,
            form: {
                name_de: '',
                name_en: '',
                desc_de: '',
                desc_en: '',
            },
            formOg: {},
            tabs: [
                {
                    name: 'Deutsch',
                    href: '#',
                },
                {
                    name: 'English',
                    href: '#',
                },
            ],
        };
    },
    beforeMount() {
        this.formOg = JSON.parse(JSON.stringify(this.form));
    },
    methods: {
        ...mapActions('products', ['addCategory']),
        async handleAdd() {
            const addCategory = await this.addCategory(this.form);
            if (addCategory.status === 200) {
                this.$router.push({name: 'ProductCategories'});
            }
        },
    },
    computed: {
        noChange() {
            return Object.keys(diff(this.formOg, this.form)).length <= 0;
        },
        saveIcon() {
            return SaveIcon;
        },
    },
};
</script>
